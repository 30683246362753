@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./assets/fonts/Poppins-Regular.ttf") format("woff");
  font-display: swap;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font: 19px / 23px Poppins, "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$primary: #4a306d;
$lightText: #fff8e8;
$buttonBackground: #d1feea;
$black: #000;
$white: #fff;

.app-header {
  background-color: $primary;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 20px 40px;
  box-shadow: 0px 4px 4px #acfcd9;
  &-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
  }
}
.app-logo {
  max-width: 230px;
  &:hover a.hideBow {
    position: relative;
    > span {
      position: absolute;
      bottom: -20px;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      display: inline;
      svg {
        width: 100%;
      }
    }
    &.bitTop > span {
      bottom: -20px;
    }
  }
  img {
    object-fit: contain;
    width: 100%;
  }
}
.app-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
  @media screen and (max-width: 1240px) {
    display: none;
  }
}
.app-menu-item {
  margin: 0 30px;
  &.btn {
    border-radius: 20px;
    background-color: #d1feea;
    padding: 20px 40px;
    .app-menu-text {
      color: $primary;
    }
  }
  &:hover a.hideBow {
    position: relative;
    > span {
      position: absolute;
      bottom: -20px;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      display: inline;
      svg {
        width: 100%;
      }
    }
    &.bitTop > span {
      bottom: -20px;
    }
  }
}
.app-menu-text {
  font-size: 36px;
  color: $lightText;
  font-weight: normal;
  text-decoration: none;
}

.app-footer {
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  background-color: $primary;
  padding: 40px 10px;
  text-align: center;
  > div {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  &-copyright {
    font-size: 30px;
    line-height: normal;
    font-weight: normal;
    color: $lightText;
    a {
      text-decoration: none;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
    }
    @media screen and (max-width: 1000px) {
      font-size: 20px;
    }
  }
  &-social {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      margin: 0 15px;
      @media screen and (max-width: 1000px) {
        margin: 20px 5px 0;
      }
      a {
        text-decoration: none;
        svg {
          @media screen and (max-width: 1000px) {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
}
.main {
  padding: 0 10px;
  margin: 70px auto 145px;
}
.main-hero {
  &-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    max-width: 1260px;
    width: 100%;
    margin: 0 auto 83px;
    @media screen and (max-width: 1000px) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
  }
  &-left {
    flex: 1 1 60%;
    @media screen and (max-width: 1000px) {
      flex: 1 1 100%;
    }
  }
  &-right {
    flex: 1 1 40%;
    text-align: right;
    @media screen and (max-width: 1000px) {
      flex: 1 1 100%;
    }
    img {
      width: 100%;
      max-width: 445px;
      object-fit: contain;
    }
  }
  &-title {
    font-size: 48px;
    line-height: normal;
    color: $primary;
    font-weight: normal;
    margin-bottom: 20px;
    @media screen and (max-width: 1000px) {
      font-size: 30px;
    }
  }
  &-text {
    font-size: 30px;
    line-height: normal;
    color: $primary;
    font-weight: normal;
    margin-bottom: 20px;
    @media screen and (max-width: 1000px) {
      font-size: 24px;
    }
  }
  &-social {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    li {
      &:first-child {
        margin-left: 0;
      }
      margin: 0 15px;
      @media screen and (max-width: 1000px) {
        margin: 0 5px;
      }
      a {
        text-decoration: none;
        svg {
          @media screen and (max-width: 1000px) {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
}
.app-menu-active {
  position: relative;
  > span {
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    svg {
      width: 100%;
    }
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }
  &.bitTop > span {
    bottom: -20px;
  }
}
.hideBow > span {
  display: none;
}

.app-breadcrumbs {
  font-size: 48px;
  line-height: 51px;
  max-width: 1300px;
  padding: 56px 10px 30px;
  margin: 0 auto;
  @media screen and (max-width: 1040px) {
    font-size: 30px;
    line-height: 35px;
  }
  a {
    text-decoration: none;
    color: $primary;
    position: relative;
    > span {
      position: absolute;
      bottom: -30px;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      svg {
        width: 100%;
      }
      @media screen and (max-width: 1040px) {
        display: none;
      }
    }
  }
  .fade {
    color: #d3bccc;
  }
}
.main-bottom {
  &-container {
    max-width: 1040px;
    margin: 0 auto;
    width: 100%;
  }
  &-text {
    font-size: 48px;
    line-height: normal;
    color: $primary;
    font-weight: 400;
    margin-bottom: 20px;
    &.marginTop {
      margin-top: 20px;
    }
    @media screen and (max-width: 1040px) {
      font-size: 30px;
      line-height: 35px;
    }
  }
}
.app-card {
  padding: 50px 72px 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background: $white;
  margin: 35px auto;
  max-width: 1033px;
  &.partial {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 1040px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  &-image {
    flex: 1 1 50%;
    max-width: 400px;
    img {
      object-fit: contain;
      width: 100%;
    }
  }
  &-details {
    flex: 1 1 50%;
    padding-left: 64px;
    > div {
      > div {
        font-weight: 400;
        font-style: normal;
        font-size: 24px;
        line-height: 25.7px;
        color: $black;
        margin-bottom: 20px;
        display: flex;
        .compress {
          width: 200px;
        }
        @media screen and (max-width: 1040px) {
          flex-direction: column;
          width: 100%;
        }
        @media screen and (max-width: 800px) {
          font-size: 18px;
          line-height: 20px;
        }
      }
    }
    > div:last-child {
      text-align: center;
      margin-top: 50px;
    }
    @media screen and (max-width: 1040px) {
      padding-left: 0;
    }
  }
  @media screen and (max-width: 1040px) {
    padding: 20px;
  }
}
.app-design {
  &-container {
    padding: 0 10px 70px;
    max-width: 1060px;
    margin: 0 auto;
  }
  &-text {
    font-size: 30px;
    line-height: normal;
    font-weight: normal;
    color: $primary;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    @media screen and (max-width: 1060px) {
      font-size: 25px;
    }
    @media screen and (max-width: 800px) {
      font-size: 20px;
    }
  }
}
.app-define-container {
  max-width: 1150px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 10px;
  flex-wrap: wrap;
  > img {
    width: 100%;
    max-width: 450px;
    @media screen and (max-width: 1000px) {
      max-width: 100%;
    }
  }
  .app-define-testimonials {
    flex: 0 1 55%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    > div {
      margin-bottom: 50px;
    }
    @media screen and (max-width: 1000px) {
      width: 100%;
      flex: 1 1 100%;
      margin-top: 20px;
    }
  }
  .app-define-text {
    font-size: 20px;
    line-height: 20px;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 20px;
    color: #02005e;
    @media screen and (max-width: 1000px) {
      font-size: 18px;
      line-height: 20px;
    }
  }
  .app-define-quote {
    font-size: 16px;
    line-height: 20px;
    font-style: normal;
    font-weight: normal;
    color: #02005e;
  }
}
.img-key-points {
  width: 100%;
  max-width: 1150px;
  margin: 0 auto 80px;
  text-align: center;
  display: block;
  padding-top: 20px;
  &.left-align {
    text-align: left;
  }
}
.title-bow {
  font-size: 48px;
  line-height: 51.41px;
  color: $primary;
  font-weight: 400;
  text-align: center;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  & span {
    margin: 0 20px;
  }
  @media screen and (max-width: 1040px) {
    font-size: 30px;
    line-height: 35px;
    & svg {
      max-width: 100px;
    }
  }
}
.list-container {
  margin: 60px auto 75px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1150px;
  > li {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 300px;
    flex: 1;
    .list-text {
      font-size: 24px;
      line-height: 30px;
      color: #02005e;
      text-align: center;
    }
    .list-content {
      padding: 11px 17px;
      background: rgba(203, 240, 248, 0.15);
      border: 1px solid #02005e;
      border-radius: 10px;
      font-size: 20px;
      line-height: 20px;
      margin-top: 25px;
      min-height: 220px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #02005e;
    }
  }
  @media screen and (max-width: 1000px) {
    padding: 0 10px;
    flex-direction: column;
    > li {
      margin-top: 20px;
      max-width: 100%;
    }
  }
}
.button-detail {
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 32px;
  color: $black;
  padding: 24px 12px;
  background: $buttonBackground;
  border-radius: 20px;
  text-decoration: none;
  display: inline-block;
  filter: drop-shadow(0px 4px 4px #4a306d);
  @media screen and (max-width: 800px) {
    font-size: 24px;
    line-height: 30px;
  }
}
.app-design-detail-btn {
  background: #ff9179;
  box-shadow: 0px 4px 4px #02005e;
  border-radius: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  color: #02005e;
  padding: 21px 16px;
  text-decoration: none;
  &.general {
    display: block;
    margin: 0 auto 75px;
    max-width: 330px;
    text-align: center;
  }
}
.app-design-details-container {
  overflow-x: hidden;
  .app-design {
    img {
      object-fit: contain;
      max-width: 1000px;
      display: block;
      width: 100%;
      margin: 100px auto;
    }
  }
  .title {
    font-size: 64px;
    line-height: 1;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #02005e;
    @media screen and (max-width: 1080px) {
      font-size: 50px;
      line-height: 55px;
    }
    @media screen and (max-width: 600px) {
      font-size: 30px;
      line-height: 35px;
    }
  }
  .app-design-header {
    background-color: #cbf0f8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    line-height: 1.25;
    font-weight: 400;
    color: #02005e;
    margin: 70px 0;
    padding: 18px 0 10px;
    > svg {
      margin-right: 20px;
    }
    @media screen and (max-width: 1000px) {
      font-size: 30px;
      line-height: 35px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
  }
  .app-design-detail-container {
    max-width: 1150px;
    padding: 0 10px;
    margin: 0 auto;
    .app-design-details {
      display: grid;
      grid-template-columns: 200px auto;
      margin-bottom: 30px;
      > div {
        text-align: left;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 20px;
        color: #02005e;
        @media screen and (max-width: 1000px) {
          font-size: 18px;
          line-height: 20px;
        }
      }
      @media screen and (max-width: 1000px) {
        grid-template-columns: auto;
      }
    }
  }
  .app-design-focus-blue {
    background: #cbf0f8;
    padding: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    > div {
      max-width: 1150px;
      width: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 20px;
      color: #02005e;
      padding: 0 10px;
    }
    @media screen and (max-width: 1000px) {
      > div {
        font-size: 18px;
        line-height: 20px;
      }
    }
  }
  .sub-title {
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 35px;
    color: #02005e;
    max-width: 1150px;
    margin: 0 auto 30px;
    padding: 0 10px;
    > span {
      background-color: #cbf0f8;
    }
    @media screen and (max-width: 1000px) {
      font-size: 30px;
      line-height: 35px;
    }
  }
  .app-design-detail-text {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    color: #02005e;
    max-width: 1150px;
    margin: 0 auto 30px;
    padding: 0 10px;
    &.small {
      text-align: right;
    }
    > span {
      background-color: #cbf0f8;
      &.big {
        font-size: 24px;
        line-height: 30px;
      }
    }
    &.center {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      @media screen and (max-width: 1000px) {
        flex-direction: column;
      }
      > div {
        flex: 0 1 50%;
        margin-right: 15px;
        @media screen and (max-width: 1000px) {
          flex: 1 1 100%;
          margin-right: 0;
        }
      }
      > img {
        flex: 0 1 50%;
        width: 50%;
        position: relative;
        @media screen and (max-width: 1000px) {
          flex: 1 1 100%;
          width: 100%;
        }
      }
    }
    a {
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      text-decoration: none;
    }
    @media screen and (max-width: 1000px) {
      font-size: 18px;
      line-height: 20px;
      span.big {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }
  .app-design-detail-image {
    max-width: 572px;
    margin: 0 auto;
    width: 100%;
    display: block;
  }
}

.app-prototype {
  margin-top: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  > img,
  > div {
    max-width: 700px;
    width: 100%;
    object-fit: contain;
    .video-react-big-play-button {
      display: none;
    }
    .video-react {
      padding-top: 0 !important;
      margin-left: -100px;
      transform: translateX(0);
      @media screen and (max-width: 900px) {
        margin-left: 0;
        transform: translateX(-15px);
        > video {
          width: 100%;
        }
      }
    }
    @media screen and (max-width: 900px) {
      max-width: 100%;
    }
  }
  &.marginBtm {
    max-width: 800px;
    justify-content: space-between;
    margin: 0 auto 90px;
    width: 100%;
    padding: 0 10px;
    > div {
      max-width: 360px;
      > div video {
        width: 100%;
      }
      + a {
        @media screen and (max-width: 900px) {
          margin-left: 0;
          margin-top: 20px;
        }
      }
    }
    @media screen and (max-width: 900px) {
      justify-content: center;
      flex-direction: column;
    }
  }
}
a.imageSmall {
  position: absolute;
  bottom: -25px;
  right: 0;
  font-size: 18px;
  line-height: 23px;
  color: #02005e;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  @media screen and (max-width: 800px) {
    position: relative;
    bottom: 0;
  }
}
.burger {
  position: absolute;
  top: 35px;
  left: 2rem;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  z-index: 101;
  display: flex;
  span {
    width: 2rem;
    height: 0.25rem;
    background: $buttonBackground;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    &:first-child {
      transform: rotate(0);
    }
    &:nth-child(2) {
      opacity: 1;
      transform: translateX(0);
    }
    &:nth-child(3) {
      transform: rotate(0);
    }
  }
  &.active span {
    background: $primary;
    &:first-child {
      transform: rotate(45deg);
    }
    &:nth-child(2) {
      opacity: 0;
      transform: translateX(20px);
    }
    &:nth-child(3) {
      transform: rotate(-45deg);
    }
  }
}

.burgerMenu {
  flex-direction: column;
  background: #fff;
  transform: translateX(-100%);
  height: 100vh;
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 100;
  padding: 2rem;
  box-shadow: 0px 4px 4px #acfcd9;
  display: flex;
  padding-top: 100px;
  a {
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: $primary;
    text-decoration: none;
    transition: color 0.3s linear;
    font-size: 1.5rem;
    text-align: left;
    &:hover {
      color: $primary;
    }
  }
  &.active {
    transform: translateX(0);
  }
}
.app-menu-burger {
  display: none;
  @media screen and (max-width: 1240px) {
    display: block;
  }
}
.app-design-cont {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1150px;
  margin: 0 auto 70px;
  padding: 0 10px;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
  &.flexed {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .app-design-cont-left {
    > div {
      font-size: 20px;
      line-height: 20px;
      font-weight: 400;
      margin-bottom: 20px;
      color: #02005e;
      span.big {
        background-color: #cbf0f8;
        font-size: 24px;
        line-height: 30px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 18px;
        line-height: 20px;
        span.big {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
    ul {
      margin-bottom: 0;
    }
  }
  .app-design-cont-right {
    padding-left: 60px;
    width: 100%;
    max-width: 670px;
    &.small {
      max-width: 340px;
    }
    @media screen and (max-width: 1000px) {
      margin-top: 20px;
      padding-left: 0;
      max-width: 100%;
    }
  }
}
.app-design-cont-left-image {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1150px;
  margin: 0 auto 80px;
  padding: 0 10px;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    padding: 0 10px;
    margin-bottom: 50px;
  }
  &.flexed {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .app-design-cont-right {
    > div {
      font-size: 20px;
      line-height: 20px;
      font-weight: 400;
      margin-bottom: 20px;
      color: #02005e;
      @media screen and (max-width: 1000px) {
        margin-top: 20px;
        font-size: 18px;
        line-height: 20px;
      }
    }
  }
  .app-design-cont-left {
    padding-right: 60px;
    width: 100%;
    max-width: 670px;
    &.small {
      max-width: 340px;
    }
    @media screen and (max-width: 1000px) {
      padding-right: 0;
      max-width: 100%;
    }
  }
}
table.first {
  margin: auto;
  max-width: 1150px;
  padding: 0 10px;
  margin: 0 auto 30px;
  th {
    text-align: left;
    color: #02005e;
  }
  tr {
    td {
      color: #02005e;
    }
    td:first-child {
      width: 200px;
      @media screen and (max-width: 1000px) {
        width: 100px;
      }
    }
    td:nth-child(2) {
      width: 500px;
      @media screen and (max-width: 1000px) {
        width: 250px;
      }
    }
  }
}

table.second {
  margin: auto;
  max-width: 1150px;
  padding: 0 10px;
  margin: 0 auto 30px;
  th {
    text-align: left;
    color: #02005e;
  }
  tr {
    td {
      color: #02005e;
    }
    td:first-child {
      width: 200px;
    }
    td:nth-child(2) {
      width: 400px;
    }
  }
}

.about-container {
  max-width: 1150px;
  margin: 60px auto;
  padding: 0 10px;
  .about-title {
    font-size: 48px;
    line-height: 72px;
    font-weight: 400;
    color: $primary;
    margin-bottom: 40px;
    @media screen and (max-width: 800px) {
      font-size: 30px;
      line-height: 35px;
    }
  }
  .about-text {
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
    color: $primary;
    margin-bottom: 20px;
    &.highMargin {
      margin-top: 96px;
      margin-bottom: 0;
      text-align: center;
      font-size: 18px;
      line-height: 20px;
    }
    @media screen and (max-width: 800px) {
      font-size: 18px;
      line-height: 20px;
    }
  }
  .about-list {
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
    color: $primary;
    margin: 0 0 20px;
    @media screen and (max-width: 800px) {
      font-size: 18px;
      line-height: 20px;
    }
    a {
      text-decoration: none;
      color: $primary;
    }
  }
  &.noBottom {
    margin-bottom: 0;
  }
  @media screen and (max-width: 800px) {
    margin: 30px 0;
  }
}
.app-about-header {
  background-color: rgba(74, 48, 109, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 34px;
  line-height: 51px;
  font-weight: 400;
  color: $primary;
  margin: 70px 0;
  padding: 18px 0 10px;
  > svg {
    margin-right: 20px;
  }
  @media screen and (max-width: 800px) {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    font-size: 24px;
    line-height: 36px;
    margin: 35px 0;
  }
}
.plane-animation {
  background: url("./assets/bckg-plane.jpg");
  overflow: hidden;
  .holder {
    max-width: 1150px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    padding: 60px 10px;
    > div {
      flex: 0 1 65%;
      @media screen and (max-width: 1100px) {
        flex: 1 1 100%;
        z-index: 0;
      }
    }
  }
  .plane {
    height: 400px;
    @media screen and (max-width: 1100px) {
      height: 350px;
    }
  }
  .planeContainer {
    position: absolute;
    top: 0;
    left: 0;
    @media screen and (max-width: 1100px) {
      left: 0;
      right: 0;
      margin: auto;
      z-index: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .plane-balloon {
    max-width: 800px;
    padding: 20px;
    margin-bottom: 64px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    .title {
      font-size: 40px;
      line-height: 42px;
      font-weight: 400;
      color: #000;
      margin-bottom: 20px;
      @media screen and (max-width: 1100px) {
        font-size: 30px;
        line-height: 35px;
      }
    }
    .sub-title {
      font-size: 24px;
      line-height: 26px;
      font-weight: 400;
      color: #000;
      @media screen and (max-width: 1100px) {
        font-size: 18px;
        line-height: 20px;
      }
    }
    @media screen and (max-width: 1100px) {
      max-width: 100%;
    }
  }
  &.active {
    .planeContainer {
      position: fixed;
      left: 0;
      top: 0;
      max-width: 1150px;
      margin: 0 auto;
      width: 100%;
      right: 0;
      @media screen and (max-width: 1100px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.imgCenter {
  max-width: 340px;
  text-align: center;
  display: block;
  margin: 0 auto;
}
.app-design-distribution {
  margin: 95px 0 75px;
  padding: 0;
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    img {
      max-width: 250px;
      width: 100%;
      object-fit: contain;
      text-align: center;
      &.mobile {
        max-width: 70px;
      }
    }
    a.app-design-detail-btn.general {
      max-width: 340px;
      font-size: 30px;
      line-height: 1;
      margin-top: 115px;
      display: inline-block;
    }
    &.marginLeft {
      margin-left: 230px;
    }
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
    li {
      &.marginLeft {
        margin-left: 0;
      }
      a.app-design-detail-btn.general {
        margin: 30px auto;
      }
    }
  }
}

.about-cont-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  &.marginDown {
    margin-bottom: 80px;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
    > a.about-btn {
      margin: 20px 0 0;
    }
  }
}
.about-btn {
  filter: drop-shadow(0px 4px 4px #4a306d);
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 32px;
  color: #000000;
  background: #d2feea;
  border-radius: 20px;
  text-decoration: none;
  max-width: 154px;
  padding: 24px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  + .about-btn {
    margin-left: 50px;
  }
  @media screen and (max-width: 800px) {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
}
.about-twin-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .about-twin-right-container {
    margin-left: 20px;
    max-height: 678px;
    max-width: 380px;
    width: 100%;
    height: 100%;
    img {
      max-height: 678px;
      max-width: 380px;
      width: 100%;
      height: 100%;
    }
    @media screen and (max-width: 800px) {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}
.about-carousel {
  margin: 50px 0;
  .about-item {
    img {
      max-width: 616px;
      max-height: 600px;
      width: 100%;
      height: 100%;
      margin: auto;
      text-align: center;
    }
  }
}
